import moment from "moment";

export const retrieveData = (
  history,
  methods,
  setPillarBanner,
  setCurrentForm
) => {
  if (!history.location.state || !history.location.state?.enquiryData) {
    return history.replace("/verification-form");
  }
  const data = history.location.state.enquiryData;
  const householdMembers = history.location.state?.householdMembers;
  const services = [];
  if (data.is_Learning_Curve__c) services.push("Learning Curve");
  if (data.is_Study_Buddy__c) services.push("Study Buddy");
  setPillarBanner(data.RecordType?.Name);
  methods.setValue("serviceTypes", services); // ! bug referesh

  // handling of the displayed form
  if (services.includes("Learning Curve")) setCurrentForm("Learning Curve");
  if (!services.includes("Learning Curve") && services.includes("Study Buddy"))
    setCurrentForm("Study Buddy");
  if (services.includes("Learning Curve") && services.includes("Study Buddy"))
    setCurrentForm("Learning Curve");

  // ------ helper function -------
  const valOrEmpty = (value) => (value ? value : "");
  const valOrFalse = (value) => (value ? value : false);

  // ------ set default values ---------
  methods.setValue(
    "academicId",
    valOrEmpty(history.location.state?.academicData?.Id)
  );
  methods.setValue("enquiryStatus", valOrEmpty(data?.EnquiryStatus__c));
  methods.setValue("enquiryId", valOrEmpty(data?.Id));
  methods.setValue(
    "subjects",
    data?.Subject__c
      ? data?.Subject__c?.split(";").map((subject) => {
          if (/a_math/i.test(subject)) return "A Math A";
          if (/e_math/i.test(subject)) return "E Math E";
          if (/combined science/i.test(subject))
            return "Combined Science (Phy/Chem)";
          return subject;
        })
      : []
  );

  // Student Information
  methods.setValue("fullName", valOrEmpty(data?.Client_s_Full_Name__c));
  methods.setValue("chineseName", valOrEmpty(data?.Client_s_Chinese_Name__c));
  methods.setValue("postalCode", valOrEmpty(data?.Postal_Code__c));
  methods.setValue("unitNumber", valOrEmpty(data?.Unit_Number__c));
  methods.setValue("address", valOrEmpty(data?.Address__c));
  methods.setValue("gender", valOrEmpty(data?.Client_s_Gender__c));
  methods.setValue("phone", valOrEmpty(data?.Client_s_Phone__c));
  if (data?.Client_s_DOB__c) {
    methods.setValue(
      "dob",
      valOrEmpty(moment(data?.Client_s_DOB__c).format("DD-MM-YYYY"))
    );
  }
  methods.setValue("contact", valOrEmpty(data?.Home_Number__c));
  methods.setValue("race", valOrEmpty(data?.Race__c));
  methods.setValue("email", valOrEmpty(data?.Client_s_Email__c));
  methods.setValue("nationality", valOrEmpty(data?.Client_s_Nationality__c));
  methods.setValue("otherNationality", valOrEmpty(data?.Other_Nationality__c));
  methods.setValue("nric", valOrEmpty(data?.Client_s_ID_No__c));
  methods.setValue("educationLevel", valOrEmpty(data?.Educational_Level__c));
  methods.setValue("stream", valOrEmpty(data?.Stream__c));
  methods.setValue("schoolName", valOrEmpty(data?.Client_s_School__c));

  let recentExamResult = history.location.state?.recentExamResult;
  if (recentExamResult && recentExamResult.length) {
    recentExamResult = recentExamResult.map((item, i) => {
      return {
        key: i + 1,
        subject: item.Subject_Name__c,
        target: item.TargetMarks__c,
        score: item.Actual_Marks__c,
      };
    });
    methods.setValue("recentExamResults", recentExamResult);
  }

  // Applicant Information
  methods.setValue(
    "relationshipToStudent",
    valOrEmpty(data?.Main_Caregiver__c)
  );
  let parentData = {};
  let spouseName = "";
  console.log("🚀 ~ file: retrieve-data.js:96 ~ data:", data);
  if (/father/i.test(data?.Main_Caregiver__c)) {
    parentData = {
      parentSalutation: data?.Salutation_Dad__c,
      parentFullName: data?.Father_s_Name__c,
      parentContact: data?.Father_s_Mobile__c,
      parentNRIC: data?.Father_s_Id_No__c,
      parentEmail: data?.Father_s_Email__c,
      parentNationality: data?.Father_s_Nationality__c,
      otherParentNationality: data?.Other_Nationality_Father__c,
    };
    spouseName = data?.Mother_s_Name__c;
  } else if (/mother/i.test(data?.Main_Caregiver__c)) {
    parentData = {
      parentSalutation: data?.Salutation_Mother__c,
      parentFullName: data?.Mother_s_Name__c,
      parentContact: data?.Mother_s_Mobile__c,
      parentNRIC: data?.Mother_s_Id_No__c,
      parentEmail: data?.Mother_s_Email__c,
      parentNationality: data?.Mother_s_Nationality__c,
      otherParentNationality: data?.Other_Nationality_Mother__c,
    };
    spouseName = data?.Father_s_Name__c;
  }
  for (const [key, value] of Object.entries(parentData)) {
    methods.setValue(key, valOrEmpty(value));
  }
  if (data?.Concerns__c) {
    let concerns = data.Concerns__c.split(";");
    methods.setValue("mainConcern", concerns);
  }

  // Subsidy Information
  methods.setValue("isSubsidy", valOrFalse(data?.Subsidy_Required__c));
  methods.setValue("spouseName", valOrEmpty(spouseName));
  if (householdMembers) {
    methods.setValue(
      "familyMembers",
      householdMembers.map((member, i) => ({
        id: i + 1,
        name: member.Name__c,
        gender: member.Gender__c,
        age: member.Age__c,
      }))
    );
  }
  methods.setValue("noFamilyMember", valOrEmpty(data?.No_of_Family_Members__c));
  methods.setValue("accomodationType", valOrEmpty(data?.Accommodation_Type__c));
  methods.setValue("houseHoldIncome", valOrEmpty(data?.Family_Gross_Salary__c));
  methods.setValue("isDeclare", valOrFalse(data?.Information_Is_True__c));
  methods.setValue(
    "isCollecting",
    valOrFalse(data?.Program_Providing_Consent__c)
  );
  methods.setValue("isUsing", valOrFalse(data?.Other_Info_Consent__c));
  methods.setValue("isPublish", valOrFalse(data?.Photo_Video_Consent__c));

  // ------ Study Buddy Form ----------
  methods.setValue("session", valOrEmpty(data?.Session_Term__c));
  methods.setValue("holidayTiming", valOrEmpty(data?.Holiday_Timing__c));
  methods.setValue("punctuality", valOrEmpty(data?.punctuality));
  methods.setValue("reasonComing", valOrEmpty(data?.Reason_for_Late__c));
  methods.setValue(
    "reasonNotAttending",
    valOrEmpty(data?.Reason_for_Non_Attendance__c)
  );
  methods.setValue("fatherName", valOrEmpty(data?.Father_s_Name__c));
  methods.setValue("fatherNric", valOrEmpty(data?.Father_s_Id_No__c));
  methods.setValue("fatherContactNo", valOrEmpty(data?.Father_s_Mobile__c));
  methods.setValue("fatherEmail", valOrEmpty(data?.Father_s_Email__c));
  methods.setValue("fatherOccupation", valOrEmpty(data?.Father_Occupation__c));
  methods.setValue("motherName", valOrEmpty(data?.Mother_s_Name__c));
  methods.setValue("motherNric", valOrEmpty(data?.Mother_s_Id_No__c));
  methods.setValue("motherContactNo", valOrEmpty(data?.Mother_s_Mobile__c));
  methods.setValue("motherEmail", valOrEmpty(data?.Mother_s_Email__c));
  methods.setValue("motherOccupation", valOrEmpty(data?.Mother_Occupation__c));
  methods.setValue(
    "maritalStatuses",
    valOrEmpty(data?.Parent_s_Marital_Status__c)
  );
  methods.setValue("mainCaregiver", valOrEmpty(data?.Main_Caregiver__c));
  methods.setValue("guardianName", valOrEmpty(data?.Guardian_s_Name__c));
  methods.setValue("guardianContactNo", valOrEmpty(data?.Guardian_s_Mobile__c));
  methods.setValue("guardianNric", valOrEmpty(data?.Guardian_s_Id_No__c));
  methods.setValue("guardianEmail", valOrEmpty(data?.Guardian_s_Email__c));
  methods.setValue("allergies", valOrEmpty(data?.Allergies_Medications__c));
  methods.setValue("sourceOfReferral", valOrEmpty(data?.Source_of_Referral__c));
  methods.setValue(
    "provideSourceOfReferral",
    valOrEmpty(data?.Name_of_Source_of_Referral__c)
  );
};
